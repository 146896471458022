import React, {useState} from 'react';
import {Button} from "@mui/material";

function App() {

  const [imageUrl, setImageUrl] = useState<string>();

  const handleImageChange = (event: React.FormEvent<HTMLInputElement>) => {
    console.log("Image changed");
    if (FileReader && event.currentTarget && event.currentTarget.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const result = reader.result as string;
        if (result) {
          setImageUrl(result);
        }
        console.log("Reader result: ", reader.result);
      }
      reader.readAsDataURL(event.currentTarget.files[0]);
    }
  }

  return (
    <div className="App">
      <input type="file" id="image_input" style={{display: "none"}} accept="image/*" onChange={handleImageChange}/>
      <label htmlFor="image_input">
        <Button variant="contained" component="span">Select Image</Button>
      </label>

      <img id="image" src={ imageUrl } alt="Selected"></img>
      <canvas id="canvas"></canvas>
    </div>
  );
}

export default App;
